import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NgMenuService {
  menuStatus$ = new BehaviorSubject<{[key:string]: {collapsed: boolean, el?: any}}>({main: {collapsed: true}});

  constructor() {
  }

  /**
   * Collapses the menu
   * Used by submenu clicks to collapse the menu
   */
  collapseMenu() {
    this.menuStatus$.next({main: {collapsed: true}});
  }

  expandMenu() {
    this.menuStatus$.next({main: {collapsed: false}});
  }
}
