<nav
  class="navbar navbar-expand-xl navbar-light fixed-top"
  style="background-color: var(--white); height: 100px"
>
  <div class="container">
    <a class="navbar-brand" routerLink="/">
      <img ngSrc="./assets/images/worxs-logo-30px.png" alt="Worxs Logo" height="30" width="152" priority="1" />
    </a>

    @if (!hideMenuItems) {
      <div
        [ngbCollapse]="isMenuCollapsed"
        [animation]="false"
        class="collapse navbar-collapse"
        id="navbarScroll"
      >
        <ul class="navbar-nav mr-auto my-2 my-lg-0 navbar-nav-scroll">
          @for (menuItem of newMenuItems; track $index) {
            <li class="my-1">
              @if (menuItem.items.length > 0) {
                <app-sub-menu
                  [menuItems]="menuItem.items"
                  [label]="menuItem.title"
                ></app-sub-menu>
              } @else {
                <a
                  [routerLink]="menuItem.path"
                  routerLinkActive="active"
                  (click)="menuService.collapseMenu()"
                >{{ menuItem.title }}
                </a>
              }
            </li>
          }
        </ul>
      </div>
    }


    <div class="navbar-end">
      @if (hideMenuItems) {
        <button
          class="language-button"
          (click)="toggleLanguageCode()"
          style="background-image: url('assets/images/denmark_24px.png')"
        ></button>
      }

      @if (!hideMenuItems) {
        <button
          class="language-button"
          (click)="toggleLanguageCode()"
          style="background-image: url('assets/images/united-kingdom_24px.png')"
        ></button>
      }

      @if (!hideMenuItems) {
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarScroll"
          aria-controls="navbarScroll"
          aria-expanded="false"
          aria-label="Toggle navigation"
          (click)="isMenuCollapsed = !isMenuCollapsed"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      }

      @if (header && header.attributes.button_text && header.attributes.button_url && !hideMenuItems) {
        <button
          [attr.data-conversion-label]="header.attributes.button_conversion_label"
          [routerLink]="header.attributes.button_url"
          class="btn btn-primary btn-sm header-button"
          (click)="isMenuCollapsed = true"
        >
          {{ header.attributes.button_text }}
        </button>
      }


      <button
        routerLink="/log-ind"
        class="btn btn-secondary btn-sm login-button"
        (click)="isMenuCollapsed = true"
      >
        {{ "Log ind" | customTranslate }}
      </button>
    </div>
  </div>
</nav>
