import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MenuItem as StrapiMenuItem} from '@strapi/types/navigation-menu';
import {NgbDropdown, NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {MegaMenu} from '@app/types/mega-menu';
import {MegamenuDirective} from '@app/directives/megamenu.directive';
import {RouterModule} from "@angular/router";
import {JsonPipe} from "@angular/common";
import {NgMenuService} from "@app/services/ng-menu.service";

@Component({
  selector: 'app-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.scss'],
  standalone: true,
  imports: [
    MegamenuDirective,
    NgbDropdownModule,
    RouterModule,
    JsonPipe
  ]
})
export class SubMenuComponent implements OnInit {

  @Input() menuItems?: StrapiMenuItem[];
  @Input() megamenu?: MegaMenu;
  @Input() label = '';

  @ViewChild('dropdown') dropdown?: NgbDropdown;

  constructor(
    public menuService: NgMenuService
  ) {
  }

  ngOnInit(): void {

  }

  onHover(event: any, dropdown: NgbDropdown | MegamenuDirective): void {
    if (event.type === 'mouseenter') {
      dropdown.open();
    }

    if (event.type === 'mouseleave') {
      dropdown.close();
    }
  }

  isIterable(value: any): boolean {
    return Symbol.iterator in Object(value);
  }
}
