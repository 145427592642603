import {Component, OnInit} from '@angular/core';
import {Router, RouterModule} from '@angular/router';
import {SingleTypesService} from 'src/app/services/single-types.service';
import {Header} from '@worxs/cms/types/header';
import {CustomTranslateService} from 'src/app/services/custom-translate.service';
import {NgbCollapse} from "@ng-bootstrap/ng-bootstrap";
import {SubMenuComponent} from "../sub-menu/sub-menu.component";
import {JsonPipe, NgOptimizedImage} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {CustomTranslatePipe} from "@app/pipes/custom-translate.pipe";
import {StrapiNavigationService} from "@strapi/services/strapi-navigation.service";
import {MenuItem as StrapiMenuItem} from "@strapi/types/navigation-menu";
import {NgMenuService} from "@app/services/ng-menu.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    RouterModule,
    NgbCollapse,
    SubMenuComponent,
    NgOptimizedImage,
    FormsModule,
    CustomTranslatePipe,
    JsonPipe
  ],
})
export class HeaderComponent implements OnInit {
  public isMenuCollapsed: boolean = true;
  public searchTerm: string = '';
  public hideMenuItems: boolean = false;

  header?: Header;
  alternativeLanguageCode?: string;
  public newMenuItems: StrapiMenuItem[] = [];

  constructor(
    private singleTypesService: SingleTypesService,
    private customTranslateService: CustomTranslateService,
    private router: Router,
    private navigationService: StrapiNavigationService,
    public menuService: NgMenuService
  ) {
    this.navigationService.fetchNavigation().subscribe(menus => {
      menus[0].render$?.subscribe((menuItems: StrapiMenuItem[]) => {
        const allMenuItems = menuItems;
        const parentMenuItems = allMenuItems.filter((item) => item.parent === null);

        for (const parentMenuItem of parentMenuItems) {
          parentMenuItem.items = allMenuItems.filter((m) => m.parent && m.parent.id === parentMenuItem.id);

          for (const submenuItem of parentMenuItem.items) {
            if (parentMenuItem.path === '#') continue;
            if (submenuItem.path.startsWith(parentMenuItem.path + '/')) continue;

            submenuItem.path = `${parentMenuItem.path}/${submenuItem.path}`;
          }
        }

        this.newMenuItems = parentMenuItems;
      })
    });
  }

  ngOnInit(): void {
    this.getHeader();

    this.customTranslateService.languageCode$.subscribe((data) => {
      this.alternativeLanguageCode =
        this.customTranslateService.getAlternativeLanguageCode(data);

      this.hideMenuItems = data !== CustomTranslateService.danish;
    });

    this.menuService.menuStatus$.subscribe((status) => {
      this.isMenuCollapsed = status.main.collapsed;
    })
  }

  getHeader(): void {
    this.singleTypesService.getHeader().subscribe((header) => {
      this.header = header;
    });
  }

  toggleLanguageCode(): void {
    this.customTranslateService.toggleLanguageCode();
    this.router.navigate(['/log-ind']);
  }
}
