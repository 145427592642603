import {Component, OnInit} from '@angular/core';
import {Service} from '@worxs/cms/types/service';
import {ServiceService} from 'src/app/services/service.service';
import {ArticleType} from '@worxs/cms/types/article-type';
import {ArticleTypeService} from 'src/app/services/article-type.service';
import {ArticleService} from '@app/services/article.service';
import {Article} from '@worxs/cms/types/article';
import {CustomTranslateService} from 'src/app/services/custom-translate.service';
import {RouterModule} from "@angular/router";
import {ContactCardComponent} from "../contact-card/contact-card.component";
import {SoMeComponent} from "../so-me/so-me.component";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  standalone: true,
  imports: [
    RouterModule,
    ContactCardComponent,
    SoMeComponent
  ]
})
export class FooterComponent implements OnInit {
  public services: Service[] = [];
  public articleTypes: ArticleType[] = [];
  public articles?: Article[];
  public hidden: boolean = false;


  constructor(
    private serviceService: ServiceService,
    private articleTypeService: ArticleTypeService,
    private articleService: ArticleService,
    private customTranslateService: CustomTranslateService
  ) {
  }

  ngOnInit() {
    this.serviceService.fetchAll().subscribe((services) => {
      this.services = services;
    });

    this.articleService.getArticles().subscribe((articles) => {
      this.articles = articles.data;
    });

    this.customTranslateService.languageCode$.subscribe((data) => {
      this.hidden = data !== CustomTranslateService.danish;
    });
  }
}
