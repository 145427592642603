import { Routes } from '@angular/router';
import { LanguageGuard } from './guards/language.guard';

export const routes: Routes = [
  {
    path: '',
    data: { animation: 'FrontPage', slug: 'forside' },
    canActivate: [LanguageGuard],
    loadComponent: () =>
      import('./components/page/page.component').then(
        (mod) => mod.PageComponent
      ),
  },

  {
    path: 'losninger/:slug',
    loadComponent: () =>
      import('./components/service-detail/service-detail.component').then(
        (mod) => mod.ServiceDetailComponent
      ),
    children: [
      {
        path: '',
        loadComponent: () =>
          import(
            './components/service-subject-card-list/service-subject-card-list.component'
          ).then((mod) => mod.ServiceSubjectCardListComponent),
        data: { animation: 'ServiceDetailPage' },
      },
      {
        path: 'emne/:slug',
        loadComponent: () =>
          import(
            './components/service-subject-detail/service-subject-detail.component'
          ).then((mod) => mod.ServiceSubjectDetailComponent),
        data: { animation: 'ServiceSubjectDetailPage' },
      },
    ],
    data: { animation: 'SingleServicePage' },
    canActivate: [LanguageGuard],
  },
  { path: 'løsninger/:slug', redirectTo: '/losninger/:slug' },
  { path: 'services/:slug', redirectTo: '/losninger/:slug' },

  { path: 'losninger/akademi', redirectTo: '/akademi' },
  { path: 'services', redirectTo: '/losninger' },
  { path: 'services/akademi', redirectTo: '/akademi' },
  { path: 'services/academy', redirectTo: '/akademi' },
  { path: 'services/webinarer', redirectTo: '/webinarer' },
  { path: 'services/webinars', redirectTo: '/webinarer' },

  {
    path: 'akademi/:slug',
    canActivate: [LanguageGuard],
    loadComponent: () =>
      import('./components/education-detail/education-detail.component').then(
        (mod) => mod.EducationDetailComponent
      ),
    loadChildren: () =>
      import('@shared/worxs/modules/course-order/course-order.module').then(
        (mod) => mod.CourseOrderModule
      ),
  },

  {
    path: 'videnscenter/artikel/:slug',
    loadComponent: () =>
      import('./components/article-detail/article-detail.component').then(
        (mod) => mod.ArticleDetailComponent
      ),
    data: { animation: 'SingleArticlePage' },
    canActivate: [LanguageGuard],
  },
  { path: 'blog/article/:slug', redirectTo: '/videnscenter/artikel/:slug' },

  {
    path: 'log-ind',
    loadComponent: () =>
      import('./components/login/login.component').then(
        (mod) => mod.LoginComponent
      ),
    data: { animation: 'LoginPage' },
  },
  { path: 'login', redirectTo: '/log-ind' },
  { path: 'module/login', redirectTo: '/log-ind' },

  {
    path: 'glemt-kodeord/:token/:languageCode',
    loadComponent: () =>
      import('./components/reset-password/reset-password.component').then(
        (mod) => mod.ResetPasswordComponent
      ),
    data: { animation: 'ResetPasswordPage' },
  },
  {
    path: 'forgot-password/:token/:languageCode',
    redirectTo: '/glemt-kodeord/:token/:languageCode',
  },
  {
    path: 'glemt-kodeord/:token',
    loadComponent: () =>
      import('./components/reset-password/reset-password.component').then(
        (mod) => mod.ResetPasswordComponent
      ),
    data: { animation: 'ResetPasswordPage' },
  },
  { path: 'forgot-password/:token', redirectTo: '/glemt-kodeord/:token' },

  {
    path: 'glemt-kodeord',
    loadComponent: () =>
      import('./components/forgot-password/forgot-password.component').then(
        (mod) => mod.ForgotPasswordComponent
      ),
    data: { animation: 'ForgotPasswordPage' },
  },
  { path: 'forgot-password', redirectTo: '/glemt-kodeord' },

  {
    path: ':slug',
    loadComponent: () =>
      import('./components/page/page.component').then(
        (mod) => mod.PageComponent
      ),
    canActivate: [LanguageGuard],
    data: { animation: 'Page' },
  },
  { path: 'cookie-policy', redirectTo: '/cookie-politik' },
  { path: 'privacy-policy', redirectTo: '/privatlivspolitik' },
  { path: 'academy', redirectTo: '/akademi' },
  { path: 'losninger/webinarer', redirectTo: '/webinarer' },
  { path: 'webinars', redirectTo: '/webinarer' },
  { path: 'blog', redirectTo: '/videnscenter' },
  { path: 'about', redirectTo: '/om-worxs' },
  { path: 'løsninger', redirectTo: 'losninger' },
];
