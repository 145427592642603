import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {SwUpdate} from "@angular/service-worker";
import {isPlatformBrowser} from "@angular/common";

@Injectable()
export class AppRefresherService {
  constructor(
    private swUpdate: SwUpdate,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {

    if (isPlatformBrowser(this.platformId)) {
      this.swUpdate.checkForUpdate().then((value: boolean) => {
        if (value) {
          this.swUpdate.activateUpdate().then(() => {
            console.warn('App updated. Refreshing');
            document.location.reload();
          });
        }
      });
    }
  }
}

export function appRefresherFactory(swUpdate: SwUpdate, platformId: any) {
  return () => new AppRefresherService(swUpdate, platformId);
}
