import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
  provideZoneChangeDetection
} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from "./app.routes";
import {provideClientHydration} from '@angular/platform-browser';
import {provideHttpClient, withInterceptors} from "@angular/common/http";
import {provideAnimationsAsync} from "@angular/platform-browser/animations/async";
import {provideMarkdown} from "ngx-markdown";
import {UrlReplacerService} from "@shared/common/services/url-replacer.service";
import {ENVIRONMENT_TOKEN} from "@worxs/types/environment";
import {EnvironmentClass} from "../environments/environment.class";
import {BsModalService} from 'ngx-bootstrap/modal';
import {hostInterceptorFn} from "@worxs/interceptors/host/hostInterceptorFn";
import {MatomoInitializationMode, provideMatomo, withRouter} from "ngx-matomo-client";
import {
  googleTagManagerConfigFactory,
  GoogleTagManagerConfiguration
} from "@shared/common/services/google-tag-manager/google-tag-manager-config.service";
import {ServiceWorkerModule} from "@angular/service-worker";
import {appRefresherFactory} from "@shared/common/services/app-refresher.service";

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })),
    provideHttpClient(withInterceptors([hostInterceptorFn])),
    provideMarkdown(),
    provideAnimationsAsync(),
    BsModalService,
    {provide: UrlReplacerService},
    {
      provide: ENVIRONMENT_TOKEN,
      useClass: EnvironmentClass,
    },
    provideMatomo(
      {
        mode: MatomoInitializationMode.MANUAL,
      }, // Your configuration
      withRouter()
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: appRefresherFactory,
      deps: [ServiceWorkerModule],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: googleTagManagerConfigFactory,
      deps: [
        GoogleTagManagerConfiguration,
        EnvironmentClass,
      ],
      multi: true,
    },
    provideZoneChangeDetection({eventCoalescing: true}), provideRouter(routes), provideClientHydration()
  ]
};
